<template>
    <div class="background">
        <div id="fake-container">
            <b-img id="photo-banner" src="../assets/Member_Banner.png"></b-img>
        </div>

        <div class="content" v-if="user.member_access === '1'">
            <div id="photo-header">MEMBER PHOTO</div>
            <div id="photo-member-name">
                {{ member.member_name_f }} {{ member.member_name_l }}
            </div>

            <b-container id="button-container" fluid>
                <a v-if="this.showtakepicture === 'yes'" class="take-picture-link" href="#" @click="takePicture">
                    <div class="take-picture-button">Take Picture</div>
                </a>
                <a v-if="this.showretakepicture === 'yes'" class="take-picture-link" href="#" @click="retakePicture">
                    <div class="take-picture-button">Retake Picture</div>
                </a>
                <a v-if="this.showsavepicture === 'yes'" class="take-picture-link" href="#" @click="savePicture">
                    <div class="save-picture-button">Save Picture</div>
                </a>
                <a class="cancel-link" href="#" @click.prevent="cancel">
                    <div class="cancel-button">CANCEL</div>
                </a>
            </b-container>

            <input id="photo-hidden-input" @change="photoUploaded" type="file" accept="image/*">

            <div id="photo-crop-frame">
                <croppa
                    v-model="myCroppa"
                    :initial-image="tempPhoto"
                    :width="225"
                    :height="225"
                    :disable-click-to-choose="true"
                    :disable-drag-and-drop="true"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="true"
                    :disable-pinch-to-zoom="true"
                    :disable-rotation="true"
                    :zoom-speed="20"
                    :quality="1"
                    :show-remove-button="this.showremovepicture"
                    placeholder="Member has no picture yet."
                    :placeholder-font-size="16"
                    @image-remove="setShowZoomButtons('no'); setShowSavePicture('no'); setShowTakePicture('yes')">
                </croppa>
            </div>

            <div id="zoom-container" v-if="this.showzoombuttons === 'yes' && this.tempPhoto != null">
                <div id="zoom-text">
                    <span id="zoom-out-button" @click="myCroppa.zoomOut()">
                        <font-awesome-icon icon="minus-circle"></font-awesome-icon>
                    </span>
                    <span class="noselect">ZOOM</span>
                    <span id="zoom-in-button" @click="myCroppa.zoomIn()">
                        <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                    </span>
                </div>

                <div id="touch-message" class="noselect">(Reposition image with touch)
                </div>

                <div id="no-delete-container" class="noselect">Photos cannot be deleted from the app. If a photo needs
                    to be deleted, please send a "Member Note" to the office.
                </div>
            </div>


        </div>

        <!-- RETAKE PICTURE WARNING MODAL -->
        <b-modal no-fade id="retake-picture-warning-modal">
            <template v-slot:modal-header="">
                <b-col>
                    <b-row align-h="center">
                        ATTENTION
                    </b-row>
                </b-col>


            </template>

            <template v-slot:default="">
                <b-container fluid>
                    <p>This member already has a photo on file. If you choose to
                    replace the photo, the original photo will no longer exist.</p>
                    <p>Are you sure you want to replace the member photo?</p>
                </b-container>

             </template>

            <template v-slot:modal-footer="">
                <b-container fluid>
                    <b-button class="modal-yes-button" @click.prevent="takePicture">
                        <div >Yes, replace.</div>
                    </b-button>
                    <b-button class="modal-cancel-button" @click.prevent="cancel">
                        <div >No, cancel.</div>
                    </b-button>
                </b-container>

            </template>
        </b-modal>


        <div class="footer">
        </div>

    </div>
</template>

<script>
import Croppa from 'vue-croppa'
import { mapActions, mapGetters } from 'vuex'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faPlusCircle, faMinusCircle)

    export default {
        name: 'photo',

        components: {
            croppa: Croppa.component
        },

        data: function() {
            return {
                myCroppa: {},
                tempPhoto: null,
                photoData: null
            }
        },

        created: function() {
            this.setShowTakePicture('yes');
            this.setShowRetakePicture('no');
            this.setShowRemovePicture(false);
            this.setShowZoomButtons('no');
            this.setShowSavePicture('no');

        },

        mounted: function() {
            this.loadPhoto();
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                member: 'members/member',
                photoerror: 'photo/photoerror',
                showtakepicture: 'photo/showtakepicture',
                showretakepicture: 'photo/showretakepicture',
                showremovepicture: 'photo/showremovepicture',
                showzoombuttons: 'photo/showzoombuttons',
                showsavepicture: 'photo/showsavepicture',
            }),

        },

        methods: {
            ...mapActions({
                setMenuTab: 'members/setMenuTab',
                setMemberPhoto: 'members/setMemberPhoto',
                setShowTakePicture: 'photo/setShowTakePicture',
                setShowRetakePicture: 'photo/setShowRetakePicture',
                setShowRemovePicture: 'photo/setShowRemovePicture',
                setShowZoomButtons: 'photo/setShowZoomButtons',
                setShowSavePicture: 'photo/setShowSavePicture',
                setPhotoData: 'photo/setPhotoData',
                uploadPhoto: 'photo/uploadPhoto',
                setElementArray: 'members/setElementArray',
                setMemberAltId: 'photo/setMemberAltId',
                turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
                turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg'
            }),

            takePicture() {
                this.$bvModal.hide('retake-picture-warning-modal');
                document.getElementById('photo-hidden-input').click();
            },

            retakePicture() {
                this.$bvModal.show('retake-picture-warning-modal');
            },


            cancel() {
                this.turnOnLoadingMsg().then(() => {
                    this.$bvModal.hide('retake-picture-warning-modal');
                    this.setElementArray('')

                    this.$router.replace({
                        name: 'members'
                    }).then(() => {
                        this.setMenuTab('SET_SHOWMEMBER');
                        this.turnOffLoadingMsg();
                    })

                })//.catch(e => { console.log(e); })

            },

            loadPhoto() {
                if(this.member.member_picture != null) {
                    this.tempPhoto = 'data:image/jpeg;base64,'+this.member.member_picture;
                    this.setShowTakePicture('no');
                    this.setShowRetakePicture('yes');
                    this.myCroppa.refresh();
                }
            },

            photoUploaded: function(event) {
                var files = event.target.files;

                if (!files.length) {
                    return
                }

                this.setTempPhoto(files[0]);

            },

            setTempPhoto(file) {
                var image = new Image()
                image.src = URL.createObjectURL(file);
                this.tempPhoto = image;
                this.myCroppa.refresh();
                this.setShowZoomButtons('yes');
                this.setShowTakePicture('no');
                this.setShowRetakePicture('no');
                this.setShowSavePicture('yes');
                this.setShowRemovePicture(true);
            },

            savePicture() {
                this.turnOnLoadingMsg().then(() => {
                    var base64data = this.myCroppa.generateDataUrl('image/jpeg');
                    this.setPhotoData(base64data).then(() => {
                        this.setMemberAltId(this.member.alt_id);
                        this.uploadPhoto().then(() => {
                            if(this.photoerror === '') {
                                var strend = base64data.length;
                                var strbeg = (base64data.length - 23) * (-1);
                                var photodata = base64data.slice(strbeg, strend);

                                this.setMemberPhoto(photodata) .then(() => {
                                    this.setElementArray('').then(() => {
                                        this.$router.replace({
                                            name: 'members'
                                        }).then(() => {
                                            this.setMenuTab('SET_SHOWMEMBER').then(() => {
                                                this.turnOffLoadingMsg();
                                            })
                                        })
                                    });
                                })


                            } else {
                                this.turnOffLoadingMsg();

                            }
                        })

                    })

                })//.catch(e => { console.log(e); })
                // console.log(this.myCroppa.generateDataUrl())

            }

        },

    }

</script>

<style scoped>

#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 40vh;
    max-height: 40vh;
}

#photo-banner {
    vertical-align: middle;
    border-style: none;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 100%;
    float: right;
    z-index: 0;
}

.content {
    background-color: #ededed;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    margin-top: 3.5em;
    max-width: 100%;
    height: calc(100vh - 5em);
}

#photo-header {
    position: absolute;
    top: 3.5em;
    z-index: 100;
    margin-left: 5vw;
    width: 90vw;
    font-weight: bold;
    font-size: 1.25em;
    color: white;
    text-align: center;
}

#photo-member-name {
    position: absolute;
    top: 7em;
    z-index: 100;
    margin-left: 5vw;
    width: 90vw;
    font-weight: bold;
    color: white;
    text-align: center;
}

#photo-hidden-input {
    visibility: hidden;
}

#button-container {
    position: absolute;
    top: 10em;
    z-index: 100;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.take-picture-link, .cancel-link {
    display: inline-block;
    min-width: 40vw;
    max-width: 40vw;
    margin-left: 7vw;
    text-align: center;
    text-decoration: none;
    outline: none;
}

.take-picture-button {
    font-weight: bold;
    color: white;
    min-width: 40vw;
    max-width: 40vw;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color:  #0f70b7;
    border: 3px solid White;
}

.save-picture-button {
    font-weight: bold;
    color: white;
    min-width: 40vw;
    max-width: 40vw;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color:  Green;
    border: 3px solid White;
}

.cancel-button {
    font-weight: bold;
    color: Red;
    min-width: 40vw;
    max-width: 40vw;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color: White;
    border: 3px solid Red;
}

#photo-crop-frame {
    position: absolute;
    top: 15em;
    margin-left: calc(50vw - 114.5px);
}

.croppa-container {
   border: 2px solid black;
}

#zoom-container {
    position: absolute;
    top: 32em;
    width: 100vw;
    text-align: center;
}

#zoom-text {
    font-size: 2em;
}

#zoom-out-button {
    margin-right: 1em;
}

#zoom-in-button {
    margin-left: 1em;

}

#touch-message {
    font-size: 1em;
    font-style: italic;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#no-delete-container {
    margin-top: 1em;
    font-size: 0.8em;
    min-width: 80%;
    max-width: 80%;
    margin-left: 10%;
    text-align: center;
}

.modal-yes-button {
    background-color: Green;
    color: White;
    border-color: Black;
    float: left;
    font-weight: bold;
}

.modal-cancel-button {
    background-color: Red;
    color: White;
    border-color: Black;
    float: right;
    font-weight: bold;
}

.footer {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    min-height: 1.5em;
    border-top: 1px solid white;
    z-index: 100;
    background-color: #0d2365;
}

</style>
